.itemOuterContainer{
  margin: 20px;
  width: 80%;
  max-width: 1520px;
  background-color: #3d3d3d;
  height: 150px;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: space-between;
}

.itemOuterContainer img{
  height: 100%;
}

.firstPart {
  display: flex;
  align-items: center;
  width: 80%;
}

.firstPart h1 {
  font-size: 35px;
  width: 68%;
  margin: auto;
}

.itemEnd{
  display: flex;
  align-items: center;
  gap: 80px;
  margin-right: 80px;
}

.itemEnd h1{
  font-size: 40px;
}

@media only screen and (max-width: 900px) {
  .itemOuterContainer{
    margin: 10px;
    width: 100%;
    height: 90px;
    max-width: none;
    background-color: #3d3d3d;
    text-decoration: none;
    color: white;
    display: flex;
    
  }
  .itemOuterContainer h1{
    font-size: 15px;
    margin: auto;
    overflow-wrap: anywhere;
  }
  .firstPart h1{
    font-size: 10px;
    width: auto;
    margin-left: 8px;
  }
  .firstPart {
    display: flex;
    align-items: none;
    gap: none;
  }
  .firstPart img{
    height: 100%;
  }
  .itemEnd h1{
    display: block;
    font-size: 10px;
    margin-right: 10px;
  }
  .itemEnd{
    width: 20%;
    display: flex;
    align-items: none;
    gap: 0;
    margin-right: 10px;
  }
}
