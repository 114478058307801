.App{
  color: white;
  padding-top: 82px;
  padding-bottom: 120px;
  background-color: #2b2b2b;
  min-height: 100vh;
}

.headerImageContainer{
  display: flex;
  width: 100%;
  height: 100%;
}

.sliderContainer{
  display: flex;
  justify-content: center;
}

.MainPageSlider{
  width: 100%;
}

.thumbnail{
  margin-top: 50px;
  margin-left: 50px;
  width: 400px;
  box-shadow: 0 0px 0px white;
  filter: drop-shadow(7px 7px 4px rgba(0, 0, 0, 0.5));
  transition: all 0.3s ease-in-out;
  border: 5px solid rgb(193, 171, 211);
  border-radius: 15px;
}

.thumbnail-mobile{
  margin-top: 50px;
  margin-left: 50px;
  width: 300px;
  box-shadow: 0 0px 0px white;
  filter: drop-shadow(7px 7px 4px rgba(0, 0, 0, 0.5));
  transition: all 0.3s ease-in-out;
  border: 5px solid rgb(193, 171, 211);
  border-radius: 15px;
}

.thumbnail-mobile:hover{
  transform: scale(1.1, 1.1);
  box-shadow: 0 0 30px white;
  transition: all 0.3s ease-in-out;
}

.thumbnail:hover{
  transform: scale(1.1, 1.1);
  box-shadow: 0 0 30px white;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 900px) {
  .App{
    width: 100%;
  }
  .thumbnail{
    margin: auto;
    display: block;
    width: 250px;
    box-shadow: 0 0px 0px white;
    filter: drop-shadow(7px 7px 4px rgba(0, 0, 0, 0.5));
    transition: all 0.3s ease-in-out;
    border: 5px solid rgb(193, 171, 211);
    border-radius: 15px;
    z-index: 200;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

