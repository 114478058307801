.contentList{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.seasonButton {
  color: white;
  text-decoration: none;
  font-size: 20px;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  border-radius: 35px;
  padding: 8px 14px;
}

