.footer{
  display: flex;
  background-color: #3d3d3d;
  gap: 30px;
  justify-content: center;
  padding: 70px;
}

.footer a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  border-radius: 35px;
  padding: 8px;
}

@media only screen and (max-width: 900px) { 
  .footer {
    flex-wrap: wrap;
    height: auto;
  }
}