h1{
  font-size: 60px;
  text-align: center;
}

.blocks{
  display: flex;
  justify-content: center;
  width: 100%;
}

.block-flex-wrap{
  flex-wrap: wrap;
}

.headerImage{
  border: 4px solid #AA6CE8;
  border-radius: 15px;
  width: 85%;
  margin: auto;
}

.headerImageMobile{
  border: 4px solid #AA6CE8;
  border-radius: 15px;
  width: 93%;
  margin: auto;
  z-index: 20;
}

.vertical-thumbnail {
  filter: grayscale(70%) drop-shadow(7px 7px 4px rgba(0, 0, 0, 0.5));
  margin-top: 50px;
  margin-left: 50px;
  width: 300px;
  box-shadow: 0 0px 0px white;
  transition: all 0.3s ease-in-out;
  border: 5px solid #c1abd3;
  border-radius: 15px; 
}

.vertical-thumbnail:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 0 0 30px white;
  transition: all 0.3s ease-in-out;
  filter: grayscale(0%); 
}

@media only screen and (max-width: 900px) {
  h1{
    font-size: 15px;
  }

  .blocks{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .vertical-thumbnail{
    margin: auto;
    width: 250px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.slick-dots{
  bottom: -35px;
}

.slick-dots li{
  width: 35px;
}

.slick-dots li button:before{
  font-size: 24px;
  color: white;
}

.slick-dots li.slick-active button:before{
  color: white;
}