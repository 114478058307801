.login{
  display: flex;
  justify-content: center;
}

h2{
  text-align: center;
}

.login-button{
  width: 100%;
}

input{
  width: 100%;
}