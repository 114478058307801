.pageContainer{
  display: flex;
  justify-content: center;
  height: 85vh;
}

.comicContainer{
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.horizontalPage{
  height: 100%;
}

.comicSlider{
  width: 90%;
}

.verticalPage{
  width: 60%;
}

.pagesContainer{
  margin-top: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.selectContainer{
  width: 100%;
  margin: auto;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.selectContainer a{
  color: white;
  text-decoration: none;
  font-size: 20px;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  border-radius: 35px;
  padding: 8px 16px;
}

.selectItem{
  width: 30%;
}

@media only screen and (max-width: 900px) {
  .comicContainer{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .pagesContainer{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .verticalPage{
    width: 100%;
  }
  
  .comicSlider{
    width: 100%;
    height: 100%;
  }

  .pageContainer{
    display: flex;
    justify-content: center;
    width: 90%;
    height: auto;
  }

  .horizontalPage{
    width: 100%;
    height: auto;
  }

  
}

.slick-dots{
  bottom: -35px;
}

.slick-dots li{
  width: 35px;
}

.slick-dots li button:before{
  font-size: 24px;
  color: white;
}

.slick-dots li.slick-active button:before{
  color: white;
}

