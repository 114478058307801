.header{
  background-color: #001381;
}

.dashboard-setting{
  padding: 40px;
}

.dashboard-button{
  width: 150px
}