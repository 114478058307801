.horizontalPageEdit{
  width: 120px;
}
.editSection{
  display: flex;
}
.textArea{
  margin: 20px;
  width: 200px;
  text-align: left;
}
.checkbox{
  width: auto;
}
.textArea input[type=text]{
  padding: 10px;
}
.textArea input[type=datetime-local]{
  padding: 10px;
}
.textArea input[type=number]{
  padding: 10px;
}
.textArea input[type=checkbox]{
  width: 20px;
  height: 20px;
}
button{
}