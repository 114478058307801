.game-title{
  margin-top: 60px;
  text-align: center;
  font-size: 500%;
}

.shenanigansLogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.shenanigansBackground{
  width: 100%;
}

.content{
  text-align: center;
  display: block;
  margin: auto;
  padding-bottom: 15px;
  width: 80%;
}

.content h2{
  padding-top: 20px;
}
