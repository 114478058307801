.header{
  position: fixed;
  width: 100vw;
  height: 82px;
  background-color: #3d3d3d;
  display: flex;
  justify-content: space-between;
  z-index: 9;
}

.headerLogo{
  margin: 5px;
}

.headerLogo img{
  height: 100%;
}

.header-icon{
  margin: 10px;
}

.sidebar-icon{
  margin: 10px;
  width: 62px;
}

.sidebarFade{
  z-index: 8;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.39);
  height: 100vh;
  width: 100%;
}

.sidebarContent{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarContent a{
  margin: 4px;
  color: white;
  text-decoration: none;
  font-size: 35px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  filter: drop-shadow(0 0 0px white); 
}

.sidebarContent .login{
  font-size: 22px;
  margin-top: auto;
  font-weight: 600;
  padding: 5px 16px;
  border: white;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px;
}

.sidebarContent.lower{
  margin-top: 125px;
}

.sidebarContent a:hover{
  transition: all 0.3s ease-in-out;
  filter: drop-shadow(0 0 2px white); 
}

.sideBar{
  position: fixed;
  width: 340px;
  background-color: #2b2b2b;
  z-index: 10;
  height: 100%;
}